import React from 'react';

import './style.css';

const BetaBadge = () => {
  const version = process.env.STORYBOOK ? '(no version)' : `${GIT_HASH}`;
  return (
    <div className="beta-badge">
      <div className="label">BETA</div>
      <span className="git">{version}</span>
    </div>
  );
};

export default BetaBadge;
