const baseUrl = process.env.REACT_APP_API_URL;

export const feedAPI = `${baseUrl}/feed`;
export const feedDetailAPI = (feedId: number) => `${baseUrl}/coffee/${feedId}`;
export const brandDetailAPI = (brandId: number) => `${baseUrl}/brand/${brandId}`;
export const searchBrandsAPI = (query: string) => `${baseUrl}/search/brand?q=${encodeURIComponent(query)}`;
export const searchCoffeesAPI = (query: string) => `${baseUrl}/search/coffee?q=${encodeURIComponent(query)}`;
export const coffeeSuggestionAPI = (query: string) => `${baseUrl}/coffee/suggest?name=${encodeURIComponent(query)}`;
export const newCoffeeAPI = `${baseUrl}/coffee`;
export const signupAPI = `${baseUrl}/user/signup`;
export const authLoginAPI = `${baseUrl}/auth/login`;

const buildAuthHeader = (authToken: string) => ({
  headers: {
    Authorization: `Bearer ${authToken}`,
  },
});

export const fetchFeed = (authToken: string) => {
  console.log('fetch feed');
  const authHeader = buildAuthHeader(authToken);
  return fetch(feedAPI, authHeader);
};

export const fetchFeedDetail = (authToken: string, feedId: number) => {
  console.log(`fetch feed with id: ${feedId}`);
  const authHeader = buildAuthHeader(authToken);
  return fetch(feedDetailAPI(feedId), authHeader);
};

export const fetchBrandDetail = (authToken: string, brandId: number) => {
  console.log(`fetch brand with id: ${brandId}`);
  const authHeader = buildAuthHeader(authToken);
  return fetch(brandDetailAPI(brandId), authHeader);
};

export const searchBrands = (authToken: string, query: string) => {
  console.log(`search brands by query: ${query}`);
  const authHeader = buildAuthHeader(authToken);
  return fetch(searchBrandsAPI(query), authHeader);
};

export const searchCoffees = (authToken: string, query: string) => {
  console.log(`search coffee by query: ${query}`);
  const authHeader = buildAuthHeader(authToken);
  return fetch(searchCoffeesAPI(query), authHeader);
};

export const fetchCoffeeSuggestions = (authToken: string, query: string) => {
  console.log(`search coffee suggestions by query: ${query}`);
  const authHeader = buildAuthHeader(authToken);
  return fetch(coffeeSuggestionAPI(query), authHeader);
};

export const submitNewCoffee = (authToken: string, data: any) => {
  console.log('submit new coffeee');
  const authHeader = buildAuthHeader(authToken);
  const requestOptions = {
    ...authHeader,
    method: 'POST',
    body: JSON.stringify(data),
  };
  return fetch(newCoffeeAPI, requestOptions);
};

export const submitUserRegistration = (data: any) => {
  console.log('submit new user registration');
  const requestOptions = {
    // call is not authenticated.
    method: 'POST',
    body: JSON.stringify(data),
  };
  return fetch(signupAPI, requestOptions);
};

export const login = (email: string, password: string) => {
  console.log(`submit login for email: ${email}`);
  const data = {
    email,
    password,
  };
  const requestOptions = {
    // call is not authenticated.
    method: 'POST',
    body: JSON.stringify(data),
  };
  return fetch(authLoginAPI, requestOptions);
};
