import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  CloseButton,
  Form,
  InputGroup,
} from 'react-bootstrap';

import useExperiments from '../../services/expirements.ts';

import './style.css';

const Experiments = () => {
  const [newExperiment, setNewExperiment] = useState<string>('');
  const history = useHistory();

  const { experiments, addExperiment, deleteExperiment } = useExperiments();

  const submitForm = () => {
    addExperiment(newExperiment);
    setNewExperiment('');
  };

  const size = 'lg';

  return (
    <div className="experiments">
      <CloseButton onClick={() => history.push('/')} />
      <h1>XP dashboard</h1>
      <div className="form">
        <InputGroup size={size}>
          <InputGroup.Text id="inputGroup-sizing-lg">New XP</InputGroup.Text>
          <Form.Control
            aria-label="Add experiment"
            aria-describedby="inputGroup-sizing-sm"
            onChange={(e) => setNewExperiment(e.target.value)}
            value={newExperiment}
          />
          <Button onClick={() => submitForm()}>add</Button>
        </InputGroup>
      </div>
      <h3>Existing Experiments</h3>
      <div className="existing">
        {experiments && experiments.map((xp) => (
          <div className="xp" key={xp}>
            {xp}
            <Button onClick={() => deleteExperiment(xp)} variant="danger" size={size}>delete</Button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Experiments;
