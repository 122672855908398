import React from 'react';
import { faHome, faPlus, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import { AuthContextType, useAuth } from '../../context/Auth.tsx';

import './style.css';

const Footer = () => {
  const { authRoles } = useAuth() as AuthContextType;
  const isAdmin = authRoles.includes('admin');

  // Add logic to enable experiment for some users.
  const isExperimentActive = true;
  const plusButtonUrl = isExperimentActive ? 'check-in' : 'new-coffee';

  return (
    <div className="app-footer">
      <div className="row">
        <div className="column">
          <Link to="/">
            <FontAwesomeIcon icon={faHome} size="xl" />
          </Link>
        </div>
        <div className="column">
          <Link to={plusButtonUrl}>
            <FontAwesomeIcon icon={faPlus} size="xl" />
          </Link>
        </div>
        {isAdmin && (
        <div className="column">
          <Link to="admin">
            <FontAwesomeIcon icon={faUserCircle} size="xl" />
          </Link>
        </div>
        )}
      </div>
    </div>
  );
};

export default Footer;
