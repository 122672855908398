import React, {
  createContext,
  useContext,
  useState,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';

import { logger } from '../logger';

// keys used for localstorage.
const authTokenKey = 'authToken';
const authRolesKey = 'authRoles';

interface AuthResult {
  token: string,
  roles: string[],
}

export type AuthContextType = {
  authToken: string | null;
  authRoles: string[];
  saveAuthResult: (result: AuthResult) => void;
  clearAuthResult: () => void;
};

const AuthContext = createContext<AuthContextType | null>(null);

interface AuthProviderProps {
  children: React.ReactNode,
}

const AuthProvider = (props: AuthProviderProps) => {
  const [authToken, setAuthToken] = useState<string | null>(null);
  const [authRoles, setAuthRoles] = useState<string[]>([]);

  const { children } = props;

  const saveAuthResult = (result: AuthResult) => {
    const { token, roles } = result;
    setAuthToken(token);
    setAuthRoles(roles);
    logger('saving auth result to local storage');
    localStorage.setItem(authTokenKey, token);
    localStorage.setItem(authRolesKey, roles.join(','));
  };

  const clearAuthResult = () => {
    setAuthToken(null);
    setAuthRoles([]);
    logger('clearing auth local storage');
    localStorage.removeItem(authTokenKey);
    localStorage.removeItem(authRolesKey);
  };

  // Read tokens from localstorage.
  // TODO verify token still valid?
  if (authToken === null && localStorage.getItem(authTokenKey)) {
    logger('loading auth-token from local storage');
    setAuthToken(localStorage.getItem(authTokenKey));
  }
  if (authRoles?.length === 0 && localStorage.getItem(authRolesKey)) {
    logger('loading auth-roles from local storage');
    const storedRoles = localStorage.getItem(authRolesKey) || '';
    setAuthRoles(storedRoles.split(','));
  }

  const value = useMemo(() => ({
    authToken,
    authRoles,
    saveAuthResult,
    clearAuthResult,
  }), [authToken, authRoles]);

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used with an AuthProvider');
  }
  return context;
};

AuthProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { AuthContext, AuthProvider, useAuth };
