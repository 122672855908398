import { useEffect, useState } from 'react';

const xpKey = 'experiments';

const useExperiments = () => {
  const [experiments, setExperiments] = useState<string[]>(() => {
    const persisted: string = localStorage.getItem(xpKey) || '';
    return persisted !== '' ? persisted.split(',') : [];
  });

  const addExperiment = (xpName: string) => {
    if (!experiments.includes(xpName)) {
      const added = [...experiments];
      added.push(xpName);
      setExperiments(added);
    }
  };

  const deleteExperiment = (xpName: string) => {
    if (experiments.includes(xpName)) {
      const removed = experiments.filter((item) => item !== xpName);
      setExperiments(removed);
    }
  };

  const isExperimentActive = (xpName: string) => experiments.includes(xpName);

  useEffect(() => {
    localStorage.setItem(xpKey, experiments.join(','));
    console.log(`saved XP local storage: ${experiments}`);
  }, [experiments]);

  return {
    experiments,
    setExperiments,
    addExperiment,
    deleteExperiment,
    isExperimentActive,
  };
};

export default useExperiments;
